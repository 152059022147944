<template>
  <div class="content">
    <div class="row">
      <div class="col-xl-4">
        <div class="block block-rounded">
          <div class="block-content">
            <router-link
              class="btn btn-lg btn-alt-primary w-100 mb-1"
              to="/wisdom/add"
              >更新账号</router-link
            >

            <p class="text-center fw-semibold fs-xs text-muted">
              若账号失效请及时更新
            </p>
          </div>
        </div>
        <div
          class="block block-rounded block-link-shadow text-center"
          href="javascript:void(0)"
        >
          <div class="block-header block-header-default">
            <h3 class="block-title">
              <i class="fa fa-fw fa-graduation-cap opacity-50"></i>
              基本信息
            </h3>
          </div>
          <div class="block-content block-content-full">
            <div class="push">
              <img alt="" class="img-avatar" :src="info.avatar" />
            </div>
            <div class="fw-semibold mb-1">
              {{ info.nickName }} ·

              <span v-if="info.state === 1" class="text-success">运行中</span>
              <span v-else class="text-danger">已失效</span>
            </div>
            <div class="fs-sm text-muted"></div>
            <button
              type="button"
              class="btn btn-sm btn-alt-primary me-1 mt-2"
              @click="execute_task()"
            >
              <i class="si si-energy"></i>立即执行
            </button>
          </div>
        </div>
        <div class="block block-rounded">
          <div class="block-header block-header-default text-center">
            <h3 class="block-title">
              <i class="fa fa-fw fa-info opacity-50"></i>
              账号信息
            </h3>
          </div>
          <div class="block-content">
            <table
              class="table table-borderless table-striped"
              style="table-layout: fixed"
            >
              <tbody>
                <tr>
                  <td>
                    <i class="si si-user"></i>
                    <span class="fs-sm">学号：{{ info.uin }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      width: 100px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    <i class="si si-pointer"></i>

                    <span class="fs-sm">地址：{{ info.address }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <div class="block block-rounded">
          <div class="block-content">
            <div class="table-responsive">
              <table class="table table-striped table-vcenter">
                <tbody>
                  <tr v-bind:class="[leave_checked ? 'show' : 'hide']">
                    <td class="text-center">
                      <i class="fw-semibold fa fa-2x si si-pointer"></i>
                    </td>
                    <td class="fw-semibold">
                      <p class="fw-semibold mb-1">打卡位置</p>
                      <p class="fs-xs text-muted mb-0">修改打卡的定位</p>
                    </td>

                    <td class="text-center">
                      <button
                        type="button"
                        class="btn btn-sm btn-alt-primary mx-2"
                        data-config="[]"
                        @click="dialogamap = true"
                      >
                        配置
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <i class="fw-semibold fa fa-2x fa-school"></i>
                    </td>

                    <td class="fw-semibold">
                      <p class="fw-semibold mb-1">离校模式</p>
                      <p class="fs-xs text-muted mb-0">是否离开了学校</p>
                    </td>

                    <td class="text-center">
                      <div class="btn-group">
                        <div class="form-check form-switch">
                          <input
                            type="checkbox"
                            v-model="leave_checked"
                            class="form-check-input"
                            @click="leave_school"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <i class="fw-semibold fa fa-2x si si-power"></i>
                    </td>
                    <td class="fw-semibold">
                      <p class="fw-semibold mb-1">打卡开关</p>
                      <p class="fs-xs text-muted mb-0">开启或关闭自动打卡</p>
                    </td>
                    <td class="text-center">
                      <div class="btn-group">
                        <div class="form-check form-switch">
                          <input
                            type="checkbox"
                            v-model="jobs_checked"
                            class="form-check-input"
                            @click="jobs_switch"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      width="80%"
      v-model="dialogamap"
      title="请选择你要打卡的定位"
      center
    >
      <el-form v-loading="loading">
        <MapContainer v-model:addressData="addressData" />
      </el-form>
      <span class="dialog-footer">
        <el-button @click="dialogamap = false">取消</el-button>
        <el-button type="primary" @click="updateAddress">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, ref } from "@vue/reactivity";

import { ElLoading } from "element-plus";
import MapContainer from "@/components/MapContainer";

export default {
  inject: ["reload"],
  name: "Info",
  data() {
    return {
      loading: ref(false),
      info: ref({}),
      dialogamap: ref(false),
      addressData: reactive({
        uin: this.$route.query.uin,
        ads: null,
        address: null,
        lonLat: null,
      }),
      jobs_checked: ref(true),
      leave_checked: ref(true),
    };
  },
  components: {
    MapContainer,
  },

  created() {
    this.getInfo().then((res) => {
      this.info = res.data;
      this.leave_checked = res.data.leaveSchool;
      this.jobs_checked = res.data.jobs;
    });
  },
  methods: {
    getInfo() {
      return this.$http.get("/wisdom/Info", {
        //params参数必写 , 如果没有参数传{}也可以
        params: {
          uin: this.$route.query.uin,
        },
      });
    },
    leave_school() {
      this.$http
        .put("/wisdom/updateLeave", {
          leaveSchool: !this.leave_checked,
          uin: this.info.uin,
        })
        .then((res) => {
          if (res.code === "200") {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.response);
        });
    },

    jobs_switch() {
      this.$http
        .put("/wisdom/updateJobs", {
          jobs: !this.jobs_checked,
          uin: this.info.uin,
        })
        .then((res) => {
          if (res.code === "200") {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    execute_task() {
      this.$confirm("你确定要立即签到吗", "自动打卡助手", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      }).then(() => {
        const loading = ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$http
          .get("/wisdom/execute", {
            //params参数必写 , 如果没有参数传{}也可以
            params: {
              uin: this.info.uin,
            },
          })
          .then((res) => {
            loading.close();
            if (res.code === "200") {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    updateAddress() {
      if (JSON.parse(JSON.stringify(this.addressData)).address == null) {
        this.$message.warning("请先点击地图选择定位");
        return false;
      }

      this.loading = true;

      this.$http.put("/wisdom/updateAddress", this.addressData).then((res) => {
        if (res.code === "200") {
          this.loading = false;

          this.$message.success(res.msg);

          this.dialogamap = false;
          this.reload();
        } else {
          this.loading = false;

          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style>
td {
  white-space: nowrap;
}

.show {
}

.hide {
  display: none;
}
</style>
